import { UserPermissionEnum } from '@/enums/UserPermissionEnum';

export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    slug: 'dashboard',
    i18n: 'Dashboard',
  },
  {
    url: '/campaigns',
    name: 'Campaigns',
    icon: 'CopyIcon',
    slug: 'campaigns',
    i18n: 'Campaigns',
  },
  {
    url: '/products',
    name: 'Products',
    icon: 'PackageIcon',
    slug: 'products',
    i18n: 'Products',
  },
  {
    url: '/product-categories',
    name: 'Product Categories',
    icon: 'FolderPlusIcon',
    slug: 'product-categories',
    i18n: 'ProductCategories',
  },
  {
    url: '/product-groups',
    name: 'Product Groups',
    icon: 'FolderPlusIcon',
    slug: 'product-groups',
    i18n: 'ProductGroups',
  },
  {
    url: '/brands',
    name: 'Brands',
    icon: 'LayersIcon',
    slug: 'brands',
    i18n: 'Brands',
  },
  {
    url: '/retailers',
    name: 'Retailers',
    icon: 'ShoppingBagIcon',
    slug: 'retailers',
    i18n: 'Retailers',
  },
  {
    url: '/users',
    name: 'Users',
    icon: 'UserIcon',
    slug: 'users',
    i18n: 'Users',
  },
  {
    url: null,
    name: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    permission: UserPermissionEnum.VIEW_SETTINGS.value,
    submenu: [
      {
        url: '/settings/currencies',
        name: 'Currencies',
        slug: 'settings-currencies',
        i18n: 'Currencies',
      },
    ],
  },
];
