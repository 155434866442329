<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <p class="text">
      <span>Copyright &copy;</span>
      <span>{{ new Date().getFullYear() }}</span>
      <strong>&nbsp;Salient Business & Knowledge Partner.&nbsp;</strong>
      <span class="hidden sm:inline-block">All rights Reserved.</span>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 12px;
}
</style>
