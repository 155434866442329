<template>
  <div>
    <v-select
      v-if="hasBaseCurrency && !loading"
      class="width-wrapper bg-white"
      label="currency"
      :value="currency"
      :clearable="false"
      :options="currencies"
      :reduce="(item) => item.currency"
      @input="changeCurrency"
    />
    <div v-else-if="loading" class="width-wrapper">
      <img src="@/assets/images/pages/loader.gif" />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';

import { SettingAction } from '@/store/actionTypes';

import moduleSettings from '@/store/settings/moduleSettings';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState('setting', {
      currencies: ({ currencies }) => currencies,
    }),

    baseCurrency() {
      return this.currencies.find((el) => el.is_base);
    },
    hasBaseCurrency() {
      return this.currencies.length && Boolean(this.baseCurrency);
    },
    currency() {
      return {
        currency: this.$store.state.currency,
        sign: this.$store.state.currencySign,
        rate: this.$store.state.currencyRate,
      };
    },
  },
  methods: {
    ...mapActions('setting', [SettingAction.fetchAll]),

    registerStoreModules() {
      if (!moduleSettings.isRegistered) {
        this.$store.registerModule('setting', moduleSettings);
        moduleSettings.isRegistered = true;
      }
    },
    changeCurrency(val) {
      if (val === null) {
        this.$store.commit('SET_DEFAULT_CURRENCY');
        return;
      }

      const selected = this.currencies.find(({ currency }) => currency === val);

      this.$store.commit('UPDATE_CURRENCY', {
        currency: selected.currency,
        currencySign: selected.sign,
        currencyRate: selected.rate,
      });
    },
    setDefault() {
      const defaultCurrency = this.hasBaseCurrency
        ? this.baseCurrency.currency
        : null;

      this.changeCurrency(defaultCurrency);
    },
  },
  async created() {
    this.registerStoreModules();

    try {
      await this.fetchAll();

      this.setDefault();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.loading = false;
  },
};
</script>

<style scoped>
.width-wrapper {
  min-width: 80px;
  text-align: center;
}
</style>
